//=========================================================================//
// [Infosheets Template Styles] //
//=========================================================================//
.infosheets {
	padding: 15px;
	width: 100%;
	float: left;
	background-color: rgba(175, 175, 175, 0.2);
	
	h3 {
		margin: 0;
		padding: 0;
		font-size: 18px;
		color: $color-default;
		text-align: center;
		font-weight: bold;
	}
	
	ul {
		margin: 15px 0 0 0;
		padding: 0;
		list-style-type: none;
	}
	
	ul > li {
		margin: 2px 0;
		border: 1px solid rgba(0, 0, 0, 0.1);
		
		a {
			display: block;
			padding: 9px 15px 9px 55px;
			background-image: url('../images/template-1/Adobe_PDF_file_icon_24x24.png');
			background-repeat: no-repeat;
			background-position: 15px center;
			color: $color-default;
			
			&:hover {
				text-decoration: none;
				background-color: white;
			}
		}
	}
}