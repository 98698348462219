//=========================================================================//
// [Page Template Styles] //
//=========================================================================//
.page {
	padding: 20px 0 0 0;
	min-height: 50vh;
	
	&.homepage
	{
		text-align: left;
		min-height: auto;
	}
}