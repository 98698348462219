//=========================================================================//
// [Header Template Styles] //
//=========================================================================//
.header {
	width: 100%;
	padding: 20px 0 30px;
	background: $header-bg-color;
	
	.h-logo {
		text-align: center;
		
		img {
			width: auto!important;
			max-width: 280px;
			margin-top: 20px;
		}
	}
	
	.container-book
	{
		padding: 20px 0;
	}
}

// Portrait tablet to landscape and desktop //
@media (min-width: 768px) and (max-width: 991px) {
	.header {
		.h-logo
		{
			text-align: center;
		}
	}
}


// Landscape phone to portrait //
@media (max-width: 767px) {
	.header {
		padding: 0 20px 20px 20px;
		
		.h-logo {
			margin-bottom: 20px;
		}
		
		.container-book
		{
			padding: 0;
		}
	}
	
	h1.logo-text a {
		color: $logo-text-mobile;
		text-align: center;
		
		&:hover,
		&:active,
		&:focus {
			color: $logo-text-hover-mobile;
		}
	}
}