//=========================================================================//
// [Buttons Template Styles] //
//=========================================================================//
.btn
{
	transition: all 200ms ease-in-out;
}
.btn-primary {
	background-color: $color-primary !important;
	border-color: $color-primary !important;
	color: white !important;
	
	&:not(:disabled):not(.disabled):active {
		background-color: darken($color-primary, 10) !important;
		border-color: darken($color-primary, 10) !important;
		color: white;
	}
	
	&:hover {
		background-color: white !important;
		color: black !important;
		border-color: black !important;
	}
	
	&:focus,
	&.focus,
	&:active {
		box-shadow: 0 0 0 0.2rem rgba($color-primary, 0.5) !important;
	}
}
.btn-book {
	background-color:  $book-btn-bg !important;
	border-color:  $book-btn-border !important;
	color: $book-btn-color !important;
	
	&:not(:disabled):not(.disabled):active {
		background-color: darken( $color-secondary, 10) !important;
		border-color: darken( $color-secondary, 10) !important;
		color: white;
	}
	
	&:hover {
		border-color: $book-btn-border-hover!important;
		background: $book-btn-bg-hover!important;
		color: $book-btn-hover!important;
	}
	
	&:focus,
	&.focus,
	&:active {
		box-shadow: 0 0 0 0.2rem rgba($color-tertiary, 0.5) !important;
	}
}
	
.btn-success {
	background-color: $color-primary;
	border-color: darken($color-primary, 15%);
	
	&:hover
	{
		background-color: white !important;
		color: black !important;
		border-color: black !important;
	}
}

.btn-warning {
	background-color: $color-tertiary;
	border-color: darken($color-tertiary, 5%);
	border-radius: 0;
	
	&:hover
	{
		background-color: white !important;
		color: black !important;
		border-color: black !important;
	}
}

.btn-phone {
	background-color: $color-secondary;
	border-color: darken($color-secondary, 5%);
	border-radius: 0;
	color: white;
	padding: 12px;
	
	&:hover
	{
		background-color: white !important;
		color: black !important;
		border-color: black !important;
	}
}

.container-book {
	position: relative;
	
	.book-btn-header {
		position: absolute;
		z-index: 1000;
		top: 0;
		right: 0;
		
		a {
			border: solid 1px $book-btn-border;
			color: $book-btn-color;
			background: $book-btn-bg;
			padding: 6px 15px;
			font-size: 14px;
			border-radius: 5px;
			
			&:hover,
			&:active,
			&:focus {
				border-color: $book-btn-border-hover;
				background: $book-btn-bg-hover;
				color: $book-btn-hover
			}
		}
	}
}

@media(max-width: 767px) {
	.container-book {
		.book-btn-header {
			position: relative;
			width: 100%;
			top: 0;
			
			a {
				width: 100%;
				display: block;
				padding: 12px 20px;
				font-size: 16px;
				border-radius: 0;
			}
		}
	}
}