//==========================================================================
//
//	Template Elements Stylesheet
//	____________________________________________________________
//
//	Date:		17/12/2018
//	Author:		Edge Marketing Solutions
//
//===========================================================================//
//                        [Font Settings]                                    //
//===========================================================================//
				  $font-decorative: Arial, Vardana, sans-serif;
					 $font-default: Arial, Vardana, sans-serif;
							$light: 300;
						  $regular: 400;
							 $bold: 700;

/*===========================================================================*/
/*                        [Colour Settings]                                  */
/*===========================================================================*/
					$color-primary: #528ecd;
				  $color-secondary: #3463ae;
				   $color-tertiary: #9d9d9d;
				 $color-quaternary: #353639;
					$color-default: #666666;

//===========================================================================//
//                    [Header and Slider Overlay Settings]                   //
//===========================================================================//
			  $header-bg-color: white;
		 $banner-overlay-color: rgba(255, 255, 255, 0.3);
  $feature-image-overlay-color: rgba($color-secondary, 0.3);
				   $gradient-1: $color-primary;
				   $gradient-2: $color-secondary;
			   $slider-overlay: $color-primary;
	   $slider-overlay-opacity: 0.75;
	   $banner-overlay-opacity: $slider-overlay-opacity;

//===========================================================================//
//                    [Navigation Settings]                                  //
//===========================================================================//
						// Navbar Desktop
					  $nav-d-color: $color-tertiary;
				$nav-d-color-hover: $color-primary;

						// Navbar Desktop Dropdown
	      $nav-d-dropdown-bg-color: white;
	$nav-d-dropdown-bg-color-hover: $color-secondary;
	      	 $nav-d-dropdown-color: $color-primary;
	   $nav-d-dropdown-color-hover: $color-secondary;
	  $nav-d-dropdown-color-active: $color-secondary;
	  $nav-d-dropdown-border-color: solid 1px rgba($color-default, 0.33);

						// Navbar Mobile
					  $nav-m-color: $color-secondary;
			   $nav-m-color-active: white;
			$nav-m-bg-color-active: $color-secondary;
		  	   $nav-m-border-color: #d4d4d4;

					$nav-bg-toggle: $color-secondary;
	   		 $nav-bg-toggle-active: darken($color-secondary, 10%);
	   $nav-bg-toggle-active-color: white;
			  $nav-bg-toggle-color: white;

			 $nav-m-dropdown-color: $nav-m-color;
	  $nav-m-dropdown-color-active: white;
				$nav-m-dropdown-bg: white;
			 $nav-m-dropdown-li-bg: $nav-m-dropdown-bg;
	  $nav-m-dropdown-li-bg-active: $color-secondary;
		$nav-m-dropdown-box-shadow: inset 0 0 10px 2px #C7C7C7;
				  $nav-m-li-border: 1px solid rgba(white, 0.5);

						$logo-text: $color-secondary;
				  $logo-text-hover: $color-default;
				 $logo-text-mobile: $color-primary;
		   $logo-text-hover-mobile: $color-default;

			  $centre-select-color: $color-primary;
		$centre-select-color-hover: white;
		   $centre-select-bg-color: transparent;
	 $centre-select-bg-color-hover: $color-primary;
			 $centre-select-border: solid 1px transparentize($color-primary, 0.75);
	   $centre-select-border-hover: solid 1px transparentize($color-primary, 0.75);

//===========================================================================//
//                        [Slider Settings]                                  //
//===========================================================================//
				   $slider-overlay: white;
			 $slider-overlay-small: $color-primary;
			   $slider-overlay-bar: rgba(white, 0.5);
	   		   $slider-text-shadow: 1px 1px 1px rgba(black, 0.9);
			  $slider-position-top: 85%;
			  		  $slider-dots: $color-tertiary;
			   $slider-dots-active: $color-primary;
					   $gradient-1: transparent;
					   $gradient-2: transparent;

//===========================================================================//
//                  [Feature Navigation Settings]                            //
//===========================================================================//
                    $feature-nav-bg: $color-secondary;
                 $feature-nav-color: white;
                 $feature-nav-title: lighten($color-primary, 15%);
           $feature-nav-title-hover: white;
          $feature-nav-btn-bg-color: $color-primary;
             $feature-nav-btn-color: white;
          $feature-nav-btn-bg-hover: white;
             $feature-nav-btn-hover: white;
       $feature-nav-btn-hover-color: $color-secondary;
      $feature-nav-btn-border-color: $color-primary;
$feature-nav-btn-border-color-hover: $color-primary;
		    $feature_nav_item_width: 33.333%;

//===========================================================================//
//                  [Book Button Settings]                                   //
//===========================================================================//
				   $book-btn-color: white;
				   $book-btn-hover: $color-secondary;
					  $book-btn-bg: $color-secondary;
				$book-btn-bg-hover: white;
				  $book-btn-border: darken($color-secondary, 10%);
		    $book-btn-border-hover: $color-secondary;

/*===========================================================================*/
/*                      [Download Settings]                                  */
/*===========================================================================*/
				      $download-bg: $color-secondary;
			$download-bg-secondary: $color-tertiary;
				   $download-color: white;
				   $download-title: lighten($color-primary, 15%);
				  $download-btn-bg: $color-primary;
			   $download-btn-color: white;
			$download-btn-bg-hover: white;
		 $download-btn-color-hover: $color-secondary;

/*===========================================================================*/
/*                        [Footer Settings]                                  */
/*===========================================================================*/
			            $footer-bg: $color-quaternary;
					 $footer-color: rgba(white, 0.66);
				   $footer-a-color: $footer-color;
				   $footer-a-hover: rgba(white, 1);
					   $row-social: $footer-color;
				 $row-social-hover: $footer-a-hover;
// [#8a3ab9 Purple Violet] [#4c68d7 Blue] [#cd486b Maroon] [#fbad50 Orange] [#fccc63 Yellow] [#bc2a8d Red Violet] [#e95950 Red Orange]
			 $row-social-instagram: $color-default;
				 		$footer-h5: $color-primary;

/*=========================================================================*/

// [Global Template Styles] //
body {
	color: $color-default;
	font-family: $font-default;
	font-weight: $regular;
}

h1 {
	color: $color-primary;
	font-size: 35px;
	font-family: $font-decorative;
	font-weight: $regular;
	
	.logo-text a {
		color: $logo-text;
		
		&:hover,
		&:active,
		&:focus {
			color: $logo-text-hover;
		}
	}
}

h2 {
	color: $color-secondary;
	font-size: 28px;
	font-family: $font-decorative;
	font-weight: $regular;
}

h3 {
	color: $color-secondary;
	font-size: 20px;
	font-family: $font-decorative;
	
	&.blog-listing
	{
		margin-top: 0;
	}
}

h4 {
	color: $color-secondary;
	font-family: $font-decorative;
	font-weight: $bold;
}

h5 {
	font-weight: bold;
	text-transform: uppercase;
	font-size: 13px;
	font-family: $font-decorative;
}

h6 {
	font-weight: $regular;
	color: $color-primary;
	font-size: 35px;
	font-family: $font-default;
	margin: 10px 0 15px;
}

a {
	transition: all 150ms ease-in-out;
	color: $color-primary;
	
	&:hover,
	&:active,
	&:focus {
		color: $color-secondary;
		text-decoration: none;
	}
}

p {
	margin-bottom: 15px;
	line-height: 1.6em;
	
	&.lead {
		color: $color-tertiary;
		font-size: 18px;
		font-weight: $regular;
	}
}

.row-spaced {
	margin-bottom: 30px;
}

img {
	width: 100%;
}

img.svg-responsive {
	width: 100% \9;
}



@import "./partials/template-1/header";

@import "./partials/template-1/navigation";

@import "./partials/template-1/slider";

@import "./partials/template-1/feature-navigation";

@import "./partials/template-1/page";

@import "./partials/template-1/buttons";

@import "./partials/chiro/download";

@import "./partials/chiro/infosheets";

@import "./partials/template-1/footer";

@import "./partials/chiro/overrides";