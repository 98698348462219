//=========================================================================//
// [Footer Template Styles] //
//=========================================================================//
.footer {
	width: 100%;
	padding: 0 0 30px 0;
	margin: 40px 0 0 0;
	background-color: $footer-bg;
	color: $footer-color;
	font-size: 12px;
	
	.arrow {
		width: 0;
		height: 0;
		margin: 0 auto 30px auto;
		border-style: solid;
		border-width: 26px 135px 0 135px;
		border-color: white transparent transparent transparent;
	}
	
	h5 {
		margin-top: 0;
		color: $footer-h5
	}
	
	a {
		color: $footer-a-color;
		
		&:hover {
			color: $footer-a-hover;
			text-decoration: none;
		}
		
		.btn {
			margin: 0 0 30px 0;
			background-color: rgba(0, 0, 0, 0.4);
			color: white;
			
			&:hover {
				background-color: white;
				color: rgba(0, 0, 0, 0.8);
			}
		}
	}
	
	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		
		> li {
			padding: 5px 0;
			border-top: 1px solid rgba(0, 0, 0, 0.1);
		}
	}
	
	.row-social {
		margin-bottom: 10px;
		
		font-size: 30px;
		text-align: right;
		
		a {
			color: $row-social;
			
			&.facebook
			{
				color: #3b5998;
			}
			
			&.instagram
			{
				font-size: 3.5rem;
				margin-left: 5px;
				color: $row-social-instagram;
			}
			
			&:hover {
				color: $row-social-hover;
			}
		}
	}
}


.logo-chiropractic-iq,
.logo-caa {
	transition: all 150ms ease-in-out;
	width: 100%;
	opacity: 0.5;
	float: right;
	
	&:hover {
		opacity: 1;
	}
}

.logo-caa {
	width: 100%;
	margin-bottom: 10px;
}

// Landscape phone to portrait tablet //
@media (max-width: 767px) {
	.footer {
		text-align: center;
		
		h5 {
			margin: 30px 0 10px 0;
		}
		
		.row-social {
			font-size: 40px;
			margin: 0;
			text-align: center;
			
			a.instagram
			{
				font-size: 4.9rem;
				margin-left: 20px;
			}
		}
	}
	.logo-chiropractic-iq {
		margin: 20px auto;
		width: 50%;
		float: none;
	}
	.logo-caa {
		width: 50%;
		margin: 40px auto 0 auto;
		float: none;
	}
}