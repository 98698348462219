//=========================================================================//
// [Feature Navigation Template Styles] //
//=========================================================================//
.feature-navigation {
	width: 100%;
	background-color: $feature-nav-bg;
	border-top: solid 2px white;
	
	h2 {
		padding: 0;
		margin: 0 0 12px 0;
		line-height: 1.0em;
		font-size: 22px;
		text-transform: uppercase;
		font-weight: bold;
		
		a {
			color: $feature-nav-title;
			
			&:hover {
				color: $feature-nav-title-hover;
				text-decoration: none;
			}
		}
	}
	
	.btn {
		margin: 15px 0 0 0;
		border: 1px solid $feature-nav-btn-border-color;
		color: $feature-nav-btn-color;
		background: $feature-nav-btn-bg-color;
		
		&:hover {
			background-color: $feature-nav-btn-bg-hover;
			color: $feature-nav-btn-hover-color;
			border: 1px solid $feature-nav-btn-border-color-hover;
		}
	}
	
	&.subpage {
		height: 12px;
	}
	
	.item {
		width: $feature_nav_item_width;
		float: left;
		padding: 30px 10px;
		box-sizing: border-box;
		border-left: 1px solid rgba(255, 255, 255, 0.5);
		text-align: center;
		color: $feature-nav-color;
		font-size: 18px;
		
		&:last-child {
			border-right: 1px solid rgba(255, 255, 255, 0.5);
		}
	}
}

.feature-navigation-arrow {
	width: 0;
	height: 0;
	margin: 0 auto;
	border-style: solid;
	border-width: 26px 135px 0 135px;
	border-color: $feature-nav-bg transparent transparent transparent;
}

// Large desktop //
@media (min-width: 1200px) {
	.feature-navigation .item h2 {
		font-size: 28px;
	}
}

// Portrait tablet to landscape and desktop //
@media (min-width: 768px) and (max-width: 991px) {
	.feature-navigation .item {
		font-size: 15px;
		
		h2 {
			font-size: 20px;
		}
	}

}

// Landscape phone to portrait tablet //
@media (max-width: 767px) {
	.feature-navigation {
		.item {
			width: 100%;
			float: left;
		    padding: 20px 0;
			box-sizing: border-box;
			border-top: 1px solid rgba(255, 255, 255, 0.5);
			border-left: none;
			text-align: center;
			color: $feature-nav-title;
			font-size: 12px;
			
			h2 {
				font-size: 18px;
			}
			
			.btn {
				margin-top: 8px;
				width: 100%;
				text-align: center;
			}
			
			&:last-child {
				border-right: none;
			}
		}
	}
}