//=========================================================================//
// [Navigation Template Styles] //
//=========================================================================//
ul.nav-desktop {
	margin: 40px 0 0 0;
	padding: 0;
	float: left;
	list-style-type: none;
	text-align: center;
	
	li {
		float: left;
		margin: 0 20px;
		position: relative;
		line-height: 1.2em;
		
		&:first-child {
			margin-left: 0;
		}
		
		&:last-child {
			margin-right: 0;
		}
		
		&.dual-line {
			margin-top: -8px;
		}
		
		a {
			transition: all 100ms ease-in-out;
			color: $nav-d-color;
			text-align: center!important;
			font-size: 13px;
			
			&:hover,
			&.active {
				color: $nav-d-color-hover;
				text-decoration: none;
			}
		}
		
		&.active a {
			color: $nav-d-color-hover;
			text-decoration: none;
		}
		
		&.dropdown-btn {
			&:hover {
				ul {
					border: $nav-d-dropdown-border-color;
					&.nav-desktop-dropdown-menu {
						display: block;
					}
				}
				
				a {
					color: $nav-d-color;
					text-decoration: none;
				}
			}
		}
	}
	
	> li > a {
		text-transform: uppercase;
	}
}

ul.nav-desktop-dropdown-menu {
	margin: 0;
	padding: 0 1em;
	position: absolute;
	width: 200px;
	left: 0;
	display: none;
	z-index: 1000;
	list-style-type: none;
	background-color: $nav-d-dropdown-bg-color;
	border-radius: 0 6px 6px 6px;
	box-shadow: 0 0 5px 0 rgba(0,0,0,0.25);
	
	li {
		margin: 15px 0 !important;
		padding: 0;
		float: none;
		text-align: left;
		
		a {
			color: $nav-d-dropdown-color !important;
			
			&:hover {
				color: $nav-d-dropdown-color-hover !important;
			}
		}
		
		&.active > a {
			color: $nav-d-dropdown-color-active !important;
		}
	}
}

.navbar-default {
	margin: 0;
	min-height: 10px;
	background-image: none;
	background-color: transparent;
	border: none;
	border-radius: 0;
	box-shadow: none;
	
	.navbar-collapse,
	.navbar-form {
		padding-left: 0;
		padding-right: 0;
		border: none;
	}
	
	.navbar-toggle {
		width: 100%;
		margin: 0;
		padding: 15px;
		background-color: $nav-bg-toggle;
		border: none;
		font-size: 16px;
		color: $nav-bg-toggle-color;
		
		&:active,
		&:hover,
		&:focus {
			background-color: $nav-bg-toggle-active;
			color: $nav-bg-toggle-active-color;
		}
	}
}

// Large desktop //
@media (min-width: 992px) and (max-width: 1199px) {
	ul.nav-desktop {
		margin: 40px 0 0 0;
		float: left;
		
		li {
			margin: 0 8px;
			
			a {
				font-size: 12px;
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.navbar-default {
		
		.navbar-nav {
			
			> li {
				
				a {
					padding: 20px 10px 0;
					color: $nav-d-color;
				}
				
				.dropdown-menu
				{
					> li
					{
						> a
						{
							font-size: 12px;
							color: $color-secondary;
							padding: 10px;
						}
					}
				}
			}
			
			> .active > a,
			> .active > a:hover,
			> .active > a:focus,
			> .open > a,
			> .open > a:hover,
			> .open > a:focus
			{
				background: transparent;
				color: $nav-d-color-hover;
			}
		}
	}
}

// Landscape phone to portrait tablet //
@media (max-width: 767px) {
	.navbar-default {
		
		.navbar-nav {
			margin: 0;
			border: solid 1px $nav-m-border-color;
			border-radius: 5px;
			background: $nav-m-dropdown-bg;
			
			> li {
				border-bottom: $nav-m-li-border;
				
				a {
					padding: 15px 18px;
					font-size: 14px;
					color: $nav-m-color;
					text-align: center;
				}
				
				&.active a
				{
					color: $nav-m-dropdown-color-active;
					background-color: $nav-m-bg-color-active;
				}
			}
			
			.open {
				.dropdown-menu {
					background-color: $nav-m-dropdown-li-bg;
					border: solid 1px $nav-m-border-color;
					box-shadow: $nav-m-dropdown-box-shadow;
					
					.active > a
					{
						background: $nav-m-dropdown-li-bg-active;
						color: $nav-m-dropdown-color-active;
					}
					
					> li > a {
						padding: 15px 18px;
						font-size: 14px;
						color: $nav-m-dropdown-color;
						text-align: center;
						background: $nav-m-dropdown-li-bg;
					}
				}
                
                > a,
                > a:hover,
                > a:focus
                {
                    background-color: $nav-m-bg-color-active;
					color: white;
                }
			}
		}
	}
}

// Hover Click Fix //
//@media (min-width: 768px) {
//  .dropdown:hover .dropdown-menu {
//    display: block;
//    margin-top: 0;
//  }
//}