//=========================================================================//
// [Slick Slider Styles] //
//=========================================================================//
.slider-overlay
{
	//display: none; // Delete to turn overlay text on
	position: absolute;
	z-index: 1000;
	width: 100%;
	top: $slider-position-top;
	transform: translateY(-$slider-position-top);

	color: $slider-overlay;
	font-size: 40px;
	font-weight: $regular;
	line-height: 4rem;
	text-align: center;
	
	text-shadow: $slider-text-shadow;
	
	small
	{
		color: $slider-overlay-small;
		font-size: 36px;
		font-weight: 300;
	}
}

.slider-wrapper-banner {
	width: 100%;
	height: 145px;
	position: relative;
	z-index: 0;
	
	.overlay-bar {
		width: 100%;
		height: 12px;
		position: absolute;
		z-index: 2;
		background-color: $slider-overlay-bar;
		
		&.top-bar {
			top: 0;
		}
		
		&.bottom-bar {
			bottom: 0;
		}
	}
}

.slick-slide
{

}

.slick-slider {
	margin-bottom: 0;
}

.slider-wrapper {
	width: 100%;
	position: relative;
}

.overlay-bar {
	width: 100%;
	height: 12px;
	position: absolute;
	z-index: 200;
	background-color: $slider-overlay-bar;
}

.top-bar {
	top: 0;
}

.bottom-bar {
	bottom: 0;
}

.slickSlider {
	img {
		display: none;
	}
	
	&.slick-initialized img {
		display: block;
	}
	
	.slick-prev {
		left: 10px;
		z-index: 100;
		opacity: 0;
	}
	
	.slick-next {
		right: 10px;
		z-index: 100;
		opacity: 0;
	}
	
	&:hover {
		.slick-next,
		.slick-prev {
			opacity: 0.75;
		}
	}
	
	&.slick-dotted
	{
		margin-bottom: 0;
	}
	
	.slick-dots
	{
		bottom: 25px;
		
		li
		{
			button
			{
				&:before{
					color: $slider-dots;
					font-size: 18px;
					opacity: 1;
				}
				
				&:hover:before,
				&:focus:before
				{
					color: $slider-dots-active;
				}
			}
			
			&.slick-active button:before
			{
				color: $slider-dots-active;
			}
		}
	}
}

// Landscape phone to portrait tablet //
@media (max-width: 767px) {
	.slick-dots {
		display: none !important;
	}
}